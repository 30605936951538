<template>
    <div class="aside">
        <!-- 经销商的侧导航栏 -->
        <h1>DEALER INFORMATION</h1>
        <div class="line"></div>
        <h2>{{obj.corporate_name}}</h2>
        <div class="info">
            <div class="address">
                <p><img src="@/assets/imgs/location.png" alt="">Dealer Address</p>
                <p class="item">{{ obj.address }},{{ obj.city }}{{ '  '+obj.state }}{{' '+ obj.postal_code }}{{' '+  obj.country }}</p>
                <p class="item"></p>
                <p class="item"></p>
                <p class="item"></p>
                <p class="item"></p>
            </div>
            <p><img src="@/assets/imgs/tel.png" alt="">{{obj.mobile}}</p>
            <p><img src="@/assets/imgs/mail.png" alt="">{{obj.email}}</p>
            <p><img src="@/assets/imgs/network.png" alt="">{{ obj.website }}</p>
        </div>
        <div class="message" @click="tomessage">
            <div class="tag" v-if="num>0">{{num}}</div>
            <!-- <el-badge :value="3" class="item"> -->
            <img src="@/assets/imgs/message.png" width="20" alt="">
            <!-- </el-badge> -->
           Notifications
        </div>

        <div class="serch">
                <el-input class="one" placeholder="VIN#" suffix-icon="el-icon-search" v-model="Model"></el-input>
                <el-select class="two" v-model="value" placeholder="Please Select">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
                <el-button @click="vinLink" class="button">SEARCH</el-button>
        </div>



        <div class="title">DEALER TOOLS</div>
        <div class="side">
            <router-link :class="$route.path.includes('inventory')?'router-link-active':''" to="/inventory/records">Inventory Record</router-link>
            <router-link :class="$route.path.includes('warranty')?'router-link-active':''" to="/warranty/register">Warranty Registrations</router-link>
            <router-link :class="$route.path.includes('claim')?'router-link-active':''" to="/claim/compensate">Warranty Claims</router-link>
            <router-link :class="$route.path.includes('recall')?'router-link-active':''" to="/recall/list">Recall Center</router-link>
            <router-link :class="$route.path.includes('crm')?'router-link-active':''" to="/crm/list">CRM</router-link>
            <router-link :class="$route.path.includes('maintain')?'router-link-active':''" to="/maintain/service">Maintenance & Service </router-link>
            <router-link :class="$route.path.includes('contact')?'router-link-active':''" to="/contact">Contact Us </router-link>
            <router-link :class="$route.path.includes('annex')?'router-link-active':''" to="/annex">Dealer Resources </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        created(){
        },

        data() {
        return {
            value: null,
            Model: '',
            options: [
                {
                    label: 'Inventory',
                    value: '/inventory/records'
                },
                {
                    label: 'Claims',
                    value: '/claim/compensate'
                },
                {
                    label: 'Recalls',
                    value: '1'
                },
                {
                    label: 'Registrations',
                    value: '/warranty/register'
                },
                {
                    label: 'Maintenances',
                    value: '/maintain/service'
                },
            ],
         
        }
    },


        props:{
            obj:{
                type:Object,
                default:()=>({})
            },
            num:{
                type:Number,
                default:0
            }
        },
        methods:{
            tomessage(){
                this.$router.push('/message')
            },
            vinLink() {
            this.$router.push({
                path: this.value,
                query: {
                    vin: this.Model
                }
            })
        },
        }
    }
</script>

<style lang="less" scoped>
    .aside {
        margin-top: 19px;
        margin-left: 2.6042vw;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        width: 15.3646vw;
        box-sizing: border-box;
        height: 95%;
        padding: 40px 20px;
        h1 {
            font-size: 18px;
            font-weight: 500;
        }
        .line {
            width: 100%;
            height: 2px;
            background: #969696;
            margin-top: 15px;
        }
        h2 {
            font-size: 12px;
            font-weight: 500;
            margin: 17px 0 12px;
        }
        .info {
            p {
                font-size: 12px;
                line-height: 20px;
                color: #3D3D3D;
                img {
                    margin-right: 10px;
                }
            }
            .address {
                .item{
                    padding-left: 20px;
                }
            }
        }
        .message {
            cursor: pointer;
            font-size: 12px;
            color: #3D3D3D;
            width: 100%;
            height: 36px;
            background: #EDEDED;
            line-height: 36px;
            padding-left: 16px;
            margin-top: 10px;
            position: relative;
            img {
                vertical-align: middle;
                margin-right: 10px;
            }
            .tag {
                padding: 0 5px;
                text-align: center;
                line-height: 16px;
                position: absolute;
                left: 30px;
                top: 2px;
                background: red;
                color: #fff;
                font-size: 12px;
                border-radius: 8px;
            }
        }
        .title {
            padding: 26px 0 13px; 
            font-size: 18px;
            font-weight: 600;
            color: #0F1423;
            border-bottom: 2px solid #969696;
        }
        .side {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 46px;
            a {
                border-bottom: 1px solid #969696;
            }
            .router-link-active {
                color: red;
            }
        }
        .serch{
            .one{
                margin-top:20px;
                width: 100%;
            }
            .two{
                margin-top: 10px;
                width: 100%;
            }
            .button{
                margin-top:10px;
                width: 100%;
            }
        }
    }
</style>