import Vue from 'vue'
// 引入ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import jquery from 'jquery'
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale, size: 'small' })
Vue.prototype.$ = jquery
//引入初始化css文件
import '@/assets/style/reset.less'
// 注入ui
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
