<template>
  <div>
    <div class="header">
      <div class="box1 cf">
        <div class="box1L">
          <a href="https://aodes.com" class="custom-logo-link">
            <img
              width="244"
              height="74"
              src="./images/header-logo-img.png"
              class="custom-logo"
              alt="AODES"
              decoding="async"
            />
          </a>
        </div>
        <!-- <div class="box1C">
          <div class="brator-search-area">
            <div class="search-form">
              <input
                class="search-field"
                id="prosearch"
                type="search"
                placeholder="Search by Part Name, Brand, Model, Sku"
              />
              <button type="submit">
                <svg
                  fill="#000000"
                  width="52"
                  height="52"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                >
                  <path
                    d="M62.1,57L44.6,42.8c3.2-4.2,5-9.3,5-14.7c0-6.5-2.5-12.5-7.1-17.1v0c-9.4-9.4-24.7-9.4-34.2,0C3.8,15.5,1.3,21.6,1.3,28c0,6.5,2.5,12.5,7.1,17.1c4.7,4.7,10.9,7.1,17.1,7.1c6.1,0,12.1-2.3,16.8-6.8l17.7,14.3c0.3,0.3,0.7,0.4,1.1,0.4 c0.5,0,1-0.2,1.4-0.6C63,58.7,62.9,57.6,62.1,57z M10.8,42.7C6.9,38.8,4.8,33.6,4.8,28s2.1-10.7,6.1-14.6c4-4,9.3-6,14.6-6c5.3,0,10.6,2,14.6,6c3.9,3.9,6.1,9.1,6.1,14.6S43.9,38.8,40,42.7C32,50.7,18.9,50.7,10.8,42.7z"
                  />
                </svg>
              </button>
              <div id="productdatasearch"></div>
            </div>
          </div>
        </div> -->
        <div class="box1R">
          <!-- <div class="login-buton">
            <a href="https://aodes.solutiontrackers.biz/my-account/" class="login">Dealer Login</a>
          </div>
          <a href="javascript:void(0)" class="login cart_tag">
            <b class="header-cart-total">
              <span class="woocommerce-Price-currencySymbol">$</span>0.00
            </b>

            <span class="header-cart-count">0</span>
          </a> -->
          <div class="login-buton" @click="outLogin">
            <p class="login2">
              Log out
              <img src="@/assets/imgs/out.png" alt />
            </p>
          </div>
        </div>
      </div>
      <div class="box2" id="nav">
        <ul>
          <li>
            <a class="mega-menu-link1" v-bind:href="shopUrl.url">HOME</a>
          </li>
          <!-- <li>
            <a
              class="mega-menu-link1"
              href="https://aodes.solutiontrackers.biz/categories-list/#atv"
            >ATV</a>
          </li>
          <li>
            <a
              class="mega-menu-link1"
              href="https://aodes.solutiontrackers.biz/categories-list/#utv"
            >UTV</a>
          </li>
          <li>
            <a
              class="mega-menu-link1"
              href="https://aodes.solutiontrackers.biz/categories-list/#snowmobile"
            >SNOWMOBILE</a>
          </li>
          <li>
            <a
              class="mega-menu-link1"
              href="https://aodes.solutiontrackers.biz/categories-list/#golf-cart"
            >GOLF CART</a>
          </li>
          <li>
            <a
              class="mega-menu-link1"
              href="https://aodes.solutiontrackers.biz/categories-list/#youth"
            >YOUTH</a>
          </li> -->
          <li class="more">
            <a class="mega-menu-link1" v-bind:href="shopUrl.url+'bundle-product/'">
              PARTS DIAGRAMS
              <!-- <span class="hasChildren" /> -->
            </a>

            <!-- <ul class="mega-sub-menu">
              <li class="mega-menu-item">
                <a
                  class="mega-menu-link mega-menu-link2"
                  href="https://aodes.solutiontrackers.biz/product-year/2021/"
                >
                  2021
                  <span class="hasChildren" />
                </a>
                <ul class="mega-sub-menu">
                  <li class="mega-menu-item">
                    <a class="mega-menu-link" href="#">
                      2021 DESERTCROSS UTVS
                      <span class="hasChildren" />
                    </a>

                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-desertcross-800-3/"
                        >2021 AODES DESERTCROSS 800-3</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-desertcross-1000-3/"
                        >2021 AODES DESERTCROSS 1000-3</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-desertcross-1000-3-hvac/"
                        >2021 AODES DESERTCROSS 1000-3 HVAC</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-desertcross-1000-6-hvac/"
                        >2021 AODES DESERTCROSS 1000-6 HVAC</a>
                      </li>
                    </ul>
                  </li>

                  <li class="mega-menu-item">
                    <a class="mega-menu-link" href="#">
                      2021 JUNGLECROSS UTVS
                      <span class="hasChildren" />
                    </a>

                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-junglecross-800-st-2/"
                        >2021 AODES JUNGLECROSS 800 ST-2</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-junglecross-800-st-5/"
                        >2021 AODES JUNGLECROSS 800 ST-5</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-junglecross-1000-lt-2/"
                        >2021 AODES JUNGLECROSS 1000 LT-2</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-junglecross-1000-lt-5/"
                        >2021 AODES JUNGLECROSS 1000 LT-5</a>
                      </li>
                    </ul>
                  </li>

                  <li class="mega-menu-item">
                    <a class="mega-menu-link" href="#">
                      2021 SPORTCROSS UTVS
                      <span class="hasChildren" />
                    </a>

                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2021-aodes-sportcross-1000-lt-2/"
                        >2021 AODES SPORTCROSS 1000 LT-2</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="mega-menu-item">
                <a
                  class="mega-menu-link mega-menu-link2"
                  href="https://aodes.solutiontrackers.biz/product-year/2022/"
                >
                  2022
                  <span class="hasChildren" />
                </a>
                <ul class="mega-sub-menu">
                  <li class="mega-menu-item">
                    <a class="mega-menu-link" href="#">
                      2022 DESERTCROSS UTVS
                      <span class="hasChildren" />
                    </a>
                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/diagram-2022-aodes-desertcross-800-3/"
                        >Diagram 2022 AODES DESERTCROSS 800-3</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8221">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-desertcross-1000-3/"
                        >2022 AODES DESERTCROSS 1000-3</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8222">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-desertcross-1000-3-hvac/"
                        >2022 AODES DESERTCROSS 1000-3 HVAC</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8223">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-desertcross-1000-6-hvac/"
                        >2022 AODES DESERTCROSS 1000-6 HVAC</a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="mega-menu-item mega-menu-item-type-custom mega-menu-item-has-children mega-menu-item-8123"
                  >
                    <a class="mega-menu-link" href="#" aria-haspopup="true" aria-expanded="false">
                      2022 JUNGLECROSS UTVS
                      <span class="hasChildren" />
                      <span class="mega-indicator" data-has-click-event="true"></span>
                    </a>
                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-junglecross-800-st-2/"
                        >2022 AODES JUNGLECROSS 800 ST-2</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8232">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-junglecross-800-st-5/"
                        >2022 AODES JUNGLECROSS 800 ST-5</a>
                      </li>
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-junglecross-1000-lt-2/"
                        >2022 AODES JUNGLECROSS 1000 LT-2</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8230">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-junglecross-1000-lt-5/"
                        >2022 AODES JUNGLECROSS 1000 LT-5</a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="mega-menu-item mega-menu-item-type-custom mega-menu-item-has-children mega-menu-item-8128"
                  >
                    <a class="mega-menu-link" href="#" aria-haspopup="true" aria-expanded="false">
                      2022 PATHCROSS ATVS
                      <span class="hasChildren" />
                      <span class="mega-indicator" data-has-click-event="true"></span>
                    </a>
                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-pathcross-650s/"
                        >2022 AODES PATHCROSS 650S</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8235">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-pathcross-650l/"
                        >2022 AODES PATHCROSS 650L</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8238">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-pathcross-800s/"
                        >2022 AODES PATHCROSS 800S</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8237">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-pathcross-800l/"
                        >2022 AODES PATHCROSS 800L</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8234">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-pathcross-1000s/"
                        >2022 AODES PATHCROSS 1000S</a>
                      </li>
                      <li class="mega-menu-item mega-menu-item-8233">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-pathcross-1000l/"
                        >2022 AODES PATHCROSS 1000L</a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="mega-menu-item mega-menu-item-type-custom mega-menu-item-has-children mega-menu-item-8134"
                  >
                    <a class="mega-menu-link" href="#" aria-haspopup="true" aria-expanded="false">
                      2022 SPORTCROSS UTVS
                      <span class="hasChildren" />
                      <span class="mega-indicator" data-has-click-event="true"></span>
                    </a>
                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-sportcross-1000-lt-2/"
                        >2022 AODES SPORTCROSS 1000 LT-2</a>
                      </li>
                    </ul>
                  </li>
                  <li class="mega-menu-item mega-menu-item-has-children mega-menu-item-8136">
                    <a class="mega-menu-link" href="#" aria-haspopup="true" aria-expanded="false">
                      2022 GOLF UTV
                      <span class="hasChildren" />
                      <span class="mega-indicator" data-has-click-event="true"></span>
                    </a>
                    <ul class="mega-sub-menu">
                      <li class="mega-menu-item">
                        <a
                          class="mega-menu-link"
                          href="https://aodes.solutiontrackers.biz/product-model/2022-aodes-golf-cart-170/"
                        >2022 AODES GOLF CART 170</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul> -->
          </li>

          <li>
            <a class="mega-menu-link1" v-bind:href="shopUrl.url+'shop/'">SHOP</a>
          </li>
          <!-- <li class="more">
            <a class="mega-menu-link1" href="#">
              CUSTOMER CARE
              <span class="hasChildren" />
            </a>
            <ul class="mega-sub-menu">
              <li
                class="mega-menu-item mega-menu-item-object-catalogs_categories mega-menu-item-8489"
              >
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/catalog-categories/accessories/"
                >Accessories</a>
              </li>
              <li
                class="mega-menu-item mega-menu-item-object-catalogs_categories mega-menu-item-2220"
              >
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/catalog-categories/warranty-policy/"
                >Warranty policy</a>
              </li>
              <li
                class="mega-vehicle-menu-color mega-menu-item mega-menu-item-object-catalogs_categories mega-menu-item-2221 vehicle-menu-color"
              >
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/catalog-categories/vehicle/"
                >Vehicle</a>
              </li>
              <li
                class="mega-menu-item mega-menu-item-object-catalogs_categories mega-menu-item-8494"
              >
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/catalog-categories/brochures/"
                >Brochures</a>
              </li>
            </ul>
          </li> -->
          <!-- <li class="more">
            <a class="mega-menu-link1" href="#">
              PARTS
              <span class="hasChildren" />
            </a>
            <ul class="mega-sub-menu">
              <li class="mega-menu-item">
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/bundle-product/"
                >Parts Diagrams</a>
              </li>
              <li class="mega-menu-item">
                <a class="mega-menu-link" href="https://aodes.solutiontrackers.biz/shop/">Shop</a>
              </li>
              <li class="mega-menu-item mega-menu-item-2591">
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/single-2/"
                >Clothing</a>
              </li>
              <li class="mega-menu-item mega-menu-item-2323">
                <a
                  class="mega-menu-link"
                  href="https://aodes.solutiontrackers.biz/single-product/"
                >Accessories</a>
              </li>
            </ul>
          </li> -->
          <li>
            <a class="mega-menu-link1" href="javascript:;">SERVICE</a>
          </li>
          <!-- <li>
            <a class="mega-menu-link1" href="/become-a-dealer/">BECOME A DEALER</a>
          </li>
          <li>
            <a
              class="mega-menu-link1"
              href="https://aodes.solutiontrackers.biz/stores/"
            >DEALER LOCATOR</a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local";
import $ from "jquery";
import shopUrl from "@/api/url";
export default {
  data(){
    return {
      shopUrl:shopUrl
    }
  },
  methods: {
    outLogin() {
      if (local.get('userInfo').role==4) {
       this.$router.push("/login");
      }else{
        local.remove('userInfo')
        local.remove('token')
        window.location.href=shopUrl.url+'my-account/'
      }
    }
  },
  created() {
    $("#nav")
      .find("li")
      .each(function() {
        if ($(this).find("ul").length > 0) {
          $('<span class="hasChildren" />').appendTo(
            $(this).children(":first")
          );
          $(this).hover(
            function() {
              $(this)
                .find(">ul")
                .stop(true, true)
                .slideDown();
            },
            function() {
              $(this)
                .find(">ul")
                .stop(true, true)
                .hide();
            }
          );
        }
      });
  },
  mounted() {
    $("#nav")
      .find("li")
      .each(function() {
        if ($(this).find("ul").length > 0) {
          $('<span class="hasChildren" />').appendTo(
            $(this).children(":first")
          );
          $(this).hover(
            function() {
              $(this)
                .find(">ul")
                .stop(true, true)
                .slideDown();
            },
            function() {
              $(this)
                .find(">ul")
                .stop(true, true)
                .hide();
            }
          );
        }
      });
  }
};
</script>

<style scoped>
@charset "utf-8";

html {
  width: 100%;
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}
body {
  max-width: 1920px;
  margin: 0 auto;
  font-family: "PingFang SC", "微软雅黑", Arial, "PingFangSC-Light",
    "Helvetica Neue", Helvetica, "Microsoft Yahei", "Hiragino Sans GB", tahoma,
    SimSun, sans-serif;
  /*font-family:"Microsoft YaHei", Helvetica, Arial, "sans-serif";*/
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #666666;
  line-height: 200%;
  background: none;
}
input,
select {
  font-family: "PingFang SC", "微软雅黑", Arial, "PingFangSC-Light",
    "Helvetica Neue", Helvetica, "Microsoft Yahei", "Hiragino Sans GB", tahoma,
    SimSun, sans-serif;
}
img {
  border: none;
  max-width: 100%;
}

dl,
dt,
dd {
  display: block;
  margin: 0;
}

p,
ul,
li,
form,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
input::-webkit-input-placeholder {
  color: #999;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}
a {
  color: #666666;
  text-decoration: none !important;
  cursor: pointer;
  background: transparent;
  outline: none;
  blr: expression(this.onFocus=this.blur());
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a img {
  border: none;
}
* {
  box-sizing: border-box;
}
.cf:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}
.wrap {
  width: 1280px;
  margin: 0px auto;
}

.clear {
  clear: both;
  margin: 0;
  padding: 0;
  height: 0;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.header {
  width: 100%;
}
.header .box1 {
  background: #fff;
  padding: 1.5625vw 5%;
}
.header .box1 .box1L {
  width: 18%;
  float: left;
}
.header .box1 .box1C {
  width: 40%;
  float: left;
  margin-top: .4167vw;
}
.header .box1 .box1R {
  flex: 1;
  float: right;
  display: flex;
  justify-content: space-between;
}
.header .box1 .box1L img {
  width: 10.4167vw;
  height: auto;
  display: block;
}
.header .box1 .box1L a {
  display: inline-block;
}
.brator-search-area {
  width: 96%;
  height: 2.3958vw;
  line-height: 2.3958vw;
  border-radius: .2083vw;
  background: #f2f2f7;
  border: 1px solid #d9d9d9;
  overflow: hidden;
}
.brator-search-area .search-form {
  height: 100%;
}
.brator-search-area .search-form button {
  float: left;
  width: 9%;
  height: 100%;
  border: 0;
  background: #f2f2f7;
}
.header .box1 .box1C .search-field {
  width: 91%;
  display: block;
  border: 0;
  background: #f2f2f7;
  height: 2.2917vw;
  line-height: 2.2917vw;
  float: right;
  padding-left: .5208vw;
  outline: none;
}

.qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.brator-header-area .col-xxl-6.col-xl-5.lg-dextop-none {
  padding-right: 0px;
}

.brator-search-area .search-form button svg {
  font-size: 1.3542vw;
}
.login {
  background-color: #000;
  color: #fff;
  padding: 0 1.4063vw;
  height: 2.8125vw;
  line-height: 2.8125vw;
  display: inline-block;
  text-decoration: none;
  transform: skewX(-10deg);
  font-size: .7813vw;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  border: 1px solid transparent;
  margin-top: .3646vw;
  font-family: "Montserrat";
}
.login2 {
  background-color: #ed3333;
  color: #fff;
  padding: .5208vw 1.4063vw;
  display: inline-block;
  text-decoration: none;
  transform: skewX(-10deg);
  font-size: .7813vw;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  border: 1px solid transparent;
  margin-top: .3646vw;
  font-family: "Montserrat";
  cursor: pointer;
}
.login2 img {
  vertical-align: middle;
}
.brator-header-area.header-one .brator-info-right a {
  background: #000;
  color: #fff !important;
  padding: .5208vw .5208vw;
}

.brator-header-area.header-one .brator-info-right a.login {
  padding: .5208vw .7813vw;
  margin-left: 1.0417vw;
  margin-top: 0;
}
.brator-header-area.header-one .brator-info-right .click-item-count {
  float: left;
}
.click-item-count {
  margin-right: .2604vw;
  position: relative;
  width: 1.5104vw;
  height: 1.4583vw;
}
.header-cart-total {
  display: block;
  background: url(./images/icon01.png) no-repeat right center;
  background-size: contain;
  height: 1.8125vw;
  line-height: 1.8125vw;
  padding-right: 1.8229vw;
  margin-top: .4167vw;
}

.header-cart-count {
  background: #f00;
  border-radius: 50%;
  color: var(--color-white);
  position: absolute;
  width: 1.0417vw;
  height: 1.0938vw;
  line-height: 1.0417vw;
  font-size: .5729vw;
  text-align: center;
  right: 2.0833vw;
  top: .2604vw;
}

.header .box2 {
  clear: both;
  width: 100%;
  background: #ed3333;
  color: #fff;
  font-size: 14px;
}
.header .box2 ul {
  width: 100%;
  padding: .5208vw 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .box2 ul li {
  height: 100%;
  display: inline-block;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.header .box2 a {
  color: #fff;
  width: 100%;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
/*.header .box2 ul li:hover {background:#333;}*/
/*.header .box2 .more {background: url(../images/icon02.png) no-repeat 97% center; padding-right: 15px; background-size: 5px;}
.header .box2 .more:hover {background:#333 url(../images/icon02.png) no-repeat 97% center; background-size: 5px;}*/

/* MENU */
#nav {
  margin: 0;
  padding: 0;
}
#nav li a,
#nav li {
  float: left;
}
#nav li {
  list-style: none;
  position: relative;
  z-index: 999;
}
#nav li a {
  text-decoration: none;
  color: white;
}
#nav li a:hover {
  background: #dddddd;
}

#nav li a.mega-menu-link1:hover {
  background: #333333;
}
#nav ul li.more ul li {
  background: #f1f1f1;
  color: #333;
}
#nav ul li.more ul li a {
  color: #333;
}
/* Submenu */
.hasChildren {
  position: absolute;
  width: .4688vw;
  height: .3125vw;
  background: url(./images/icon02.png) no-repeat 97% center;
  right: 0;
  bottom: .8333vw;
  background-size: .3125vw;
}
#nav li.more .mega-menu-link1 {
  padding-right: 20px;
}
#nav li.more .mega-menu-link1 .hasChildren {
  right: 8px;
}
#nav ul .mega-menu-link .hasChildren {
  background: url(./images/icon02-on.png) no-repeat 90% center;
  background-size: 6px;
  right: .7292vw;
}

#nav li ul .hasChildren {
  background: url(./images/icon02.png) no-repeat 97% center;
  /*border-color: #f1f1f1 #f1f1f1 #f1f1f1 #666666;*/
  bottom: .7813vw;
  right: .2604vw;
}
#nav li ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0;
  margin: 0;
}

#nav li:hover > ul {
  display: block;
}
#nav li ul li,
#nav li ul li a {
  float: none;
}
* html #nav li ul li {
  display: inline; /* for IE6 */
}
#nav li ul li a {
  width: 16.6667vw;
  display: block;
}
#nav li ul li a.mega-menu-link2 {
  width: 9.375vw;
}
/* SUBSUB Menu */
#nav li ul li ul {
  display: none;
}
#nav li ul li:hover ul {
  left: 100%;
  top: 0;
}

.footer {
  clear: both;
  width: 100%;
  background: url(./images/foote-bg.png) no-repeat center center;
  background-size: cover;
}
.footer .list {
  width: 60.4167vw;
  margin: 0 auto;
  overflow: hidden;
}
.footer .box1-bg {
  width: 80%;
  margin: 0 auto;
  background-image: url(./images/foote-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5%;
}
.footer .list .box1 {
  clear: both;
  border-bottom: 1px solid #999;
  padding-bottom: 3.9063vw;
  margin-bottom: 3.125vw;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.footer .list .box2 {
  clear: both;
  padding-bottom: 6%;
}
.footer .list .box2 .box2L {
  width: 30%;
  float: left;
}
.footer .list .box2 .box2R {
  width: 65%;
  float: right;
}
.footer .list .box2 .box2L .im {
  display: block;
  margin-bottom: 1.8229vw;
  width: 9.9479vw;
  height: auto;
}
svg {
  width: 1em;
  height: 1em;
  font-size: 1.3542vw;
}
.brator-social-link {
  justify-content: center !important;
}
.brator-social-link a {
  margin-right: .2604vw;
}
.brator-social-link a svg {
  font-size: 2.0833vw !important;
  color: #fff !important;
}
.e-fab-facebook-f {
  background-color: #1c4b8c;
  fill: #fff !important;
  padding: 1px .6771vw;
  border-radius: 100%;
}
.e-fab-twitter {
  background-color: #54abee;
  fill: #fff !important;
  padding: 1px .4167vw;
  border-radius: 100%;
}
.e-fab-instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    300deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    300deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  fill: #fff !important;
  padding: 1px 10px;
  border-radius: 100%;
}
.e-fab-youtube {
  background-color: #e02f2f;
  fill: #fff !important;
  padding: 1px 10px;
  border-radius: 100%;
}
.footer .list .box2 .box2R .li {
  width: 33.333%;
  float: left;
}
.footer .list .box2 .box2R .li .footer-top-title {
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 1.3021vw;
}
.footer .list .box2 .box2R .li .brator-link-list-one ul li {
  margin-bottom: 18px;
}
.footer .list .box2 .box2R .li .brator-link-list-one a {
  color: #666;
  font-size: 14px;
}

.footer .box3 {
  clear: both;
  background-image: url(./images/foote-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.6042vw 0;
}
.footer .box3 .list {
  height: 2.0833vw;
}
.footer .box3 .box3L {
  width: 30%;
  float: left;
  line-height: 40px;
}
.footer .box3 .box3R {
  width: 65%;
  float: right;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer .box3 .box3R a {
  margin-left: 30px;
}
.brator-payment-title {
  line-height: 1.0417vw;
  font-size: 14px;
  color: #666;
  margin-right: 1.5625vw;
  font-weight: bold;
}

@media (max-width: 1440px) {
  .footer .list {
    width: 49.4792vw;
  }

  .footer .list .box2 .box2R .li .footer-top-title {
    font-size: 14px;
  }
  .footer .list .box2 .box2R .li .brator-link-list-one a {
    font-size: 12px;
  }

  .login {
    padding: 0 .8854vw;
  }
}
</style>