//引入设置后的http
import http from './http'
//登录接口
export const api_users_Login = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Admin/Login',
        method: 'post',
        data,
        dataType:'jsonp'
    })
}
/***************库存接口 start *****************/
//库存列表
export const $api_registerList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/registerList',
        method: 'post',
        data,
    })
}

//获取库存详情
export const $api_StockDetail = (data) =>{
    // 调用axios
    return http({
        url: '/index.php/api/Stock/detail',
        method: 'post',
        data,
    })
}
//查询库存详情
export const $api_stock_detail = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/detail',
        method: 'post',
        data,
    })
}

//库存删除
export const $api_stock_delete = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/delete',
        method: 'post',
        data,
    })
}

/***************库存接口 end *****************/


/***************注册信息接口 start *****************/
//注册列表接口
export const $api_stockList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/list',
        method: 'post',
        data,
    })
}

//注册信息保存接口
export const $api_registerSave = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/registerSave',
        method: 'post',
        data,
    })
}

//注册信息删除接口
export const $api_registerDelete = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/registerDelete',
        method: 'post',
        data,
    })
}

/***************注册信息接口 end *****************/

/***************索赔接口 start *****************/
//索赔列表接口
export const $api_claimList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Claim/list',
        method: 'post',
        data,
    })
}
//索赔保存接口
export const $api_ClaimSave = (data)=>{
    return http({
        url: '/index.php/api/Claim/save',
        method: 'post',
        data,
    })
}

//索赔详情
export const $api_ClaimDatainfo = (data)=>{
    return http({
        url: '/index.php/api/Claim/details',
        method: 'post',
        data,
    })
}

//索赔详情
export const $api_ClaimlistLog = (data)=>{
    return http({
        url: '/index.php/api/Claim/listLog',
        method: 'post',
        data,
    })
}


/***************索赔接口 end *****************/

/***************维修保养 start *****************/
//索赔列表接口
export const $api_MaintenancesList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Maintenances/list',
        method: 'post',
        data,
    })
}
/***************维修保养 end *****************/


/***************共享资料 start *****************/
//索赔列表接口
export const $api_AttachmentList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Attachment/list',
        method: 'post',
        data,
    })
}

/***************共享资料 end *****************/

/***************站内消息 start *****************/
//站内消息列表
export const $api_mailList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Admin/mail',
        method: 'post',
        data,
    })
}
/***************站内消息 end *****************/
//新建维保接口
export const $api_newMaintenances = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Maintenances/save',
        method: 'post',
        data,
    })
}

/*************** 项目接口  start *****************/
export const $api_ProjectList = (data) =>{
    return http({
        url: '/index.php/api/Project/list',
        method: 'post',
        data,
    })
}
/*************** 项目接口 end *****************/
//获取配件列表
export const $api_ServiceList = (data) =>{
    return http({
        url: '/index.php/api/Service/list',
        method: 'post',
        data,
    })
}
//销售端保存修改
export const $api_Stock_save = (data) =>{
    return http({
        url: '/index.php/api/Stock/save',
        method: 'post',
        data,
    })
}
//销售端库存列表
export const $api_Stock_list = (data) =>{
    return http({
        url: '/index.php/api/Stock/list',
        method: 'post',
        data,
    })
}
//获取经销商列表
export const $api_Stock_getDealerList = (data) =>{
    return http({
        url: '/index.php/api/Stock/getDealerList',
        method: 'post',
        data,
    })
}
//经销商修改状态
export const $api_Stock_stateSave = (data) =>{
    return http({
        url: '/index.php/api/Stock/stateSave',
        method: 'post',
        data,
    })
}
export const $api_Classification_list = (data) =>{
    return http({
        url: '/index.php/api/Classification/list',
        method: 'post',
        data,
    })
}
//经销商维修保养详情
export const $api_Maintenances_detail = (data) =>{
    return http({
        url: '/index.php/api/Maintenances/detail',
        method: 'post',
        data,
    })
}
//索赔日志详情
export const $api_ClaimdetailsLog = (data) =>{
    return http({
        url: '/index.php/api/Claim/detailsLog',
        method: 'post',
        data,
    })
}
//单条修改索赔项目
export const $api_singleClaimItemsEdit = (data) =>{
    return http({
        url: '/index.php/api/Claim/singleClaimItemsEdit',
        method: 'post',
        data,
    })
}
//单条修改索赔项目
export const $api_singleClaimPartsEdit = (data) =>{
    return http({
        url: '/index.php/api/Claim/singleClaimPartsEdit',
        method: 'post',
        data,
    })
}
//获取验证码
export const $api_sendEmailCode = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Admin/sendEmailCode',
        method: 'post',
        data,
        dataType:'jsonp'
    })
}
//修改密码
export const $api_setNewPassword = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Admin/setNewPassword',
        method: 'post',
        data,
        dataType:'jsonp'
    })
}
//下载模板
export const $api_downloadExcel = () => {
    // 调用axios
    return http({
        url: '/index.php/api/stock/download',
        method: 'post',
        dataType:'jsonp'
    })
}
//批量导入
export const $api_updateExcel = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/stock/updateExcel',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//验证是否正常保养
export const $api_maintainIsStandard = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/MaintenancePlan/upkeep_plan',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//未读消息条数
export const $api_unread_count = (data) => {
    // 调用axios
    return http({
        url: 'index.php/api/Admin/unread_count/',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//修改消息状态
export const $api_edit_look = (data) => {
    // 调用axios
    return http({
        url: 'index.php/api/Admin/edit_look',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//服务项目列表
export const $api_project_list = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/project_list',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//用户详情
export const $api_Admin_detail = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Admin/detail',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//获取年份
export const $api_year_list = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Classification/year_list',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//经销商端查询经销商
export const $api_dealerList = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Stock/dealerList',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//顶部菜单
export const $api_menuList = () => {
    // 调用axios
    return http({
        url: 'index.php/api/Exterior/top_list',
        method: 'get',
        dataType:'jsonp',
    })
}
//首页需要关注
export const $api_attention_statistics = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Panel/attention_statistics',
        method: 'get',
        dataType:'jsonp',
        params:data
    })
}
//首页库存统计
export const $api_inventory_statistics = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Panel/inventory_statistics',
        method: 'get',
        dataType:'jsonp',
        params:data
    })
}
//首页索赔列表
export const $api_latest_claim = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Panel/latest_claim',
        method: 'get',
        dataType:'jsonp',
        params:data
    })
}
//索赔历史查询
export const $api_frame_verify = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/claim/frame_verify',
        method: 'post',
        dataType:'jsonp',
        params:data
    })
}
//召回列表
export const $api_recall_list = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Recall/recall_list',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//召回详情
export const $api_recall_details = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Recall/recall_details',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//验证是否可以处理
export const $api_apply_verify = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Recall/apply_verify',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//召回车辆详情
export const $api_frame_details = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Recall/frame_details',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//召回提交
export const $api_submit_apply = (data) => {
    // 调用axios
    return http({
        url: 'index.php/api/Recall/submit_apply',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//召回历史
export const $api_frame_record = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/Recall/frame_record',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//crm列表
export const $api_crm_data = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/recall/crm_data',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//联系我们列表
export const $api_contact_us = () => {
    // 调用axios
    return http({
        url: '/index.php/api/recall/contact_us',
        method: 'post',
        dataType:'jsonp',
    })
}
//附件列表
export const $api_share_file = (data) => {
    // 调用axios
    return http({
        url: '/index.php/api/recall/share_file',
        method: 'post',
        dataType:'jsonp',
        data
    })
}
//首页召回信息
export const $api_home_recall_list = () => {
    // 调用axios
    return http({
        url: '/index.php/api/panel/recall_list',
        method: 'post',
        dataType:'jsonp',
    })
}
//首页共享附件
export const $api_home_share_file = () => {
    // 调用axios
    return http({
        url: '/index.php/api/panel/share_file',
        method: 'post',
        dataType:'jsonp',
    })
}

//获取城市



export const $api_country = () => {
    // 调用axios 
    return http({
        url: '/index.php/api/country/CountryList',
        method: 'get',
        dataType:'jsonp',
    })
}

